<template>
	<div class="wrapper">
		<section class="agreement-section">
			<div class="container">
				<div class="agreement">
					<tk-button @click="goBack" kind="back-button">
						<template #icon-before>
							<tk-svg type="arrow-top" />
						</template>
						Назад
					</tk-button>
					<h1>Правила защиты информации о пользователях приложения Teremok</h1>

					<ol>
						<li>
							<p><strong>Общие положения</strong></p>
							<ol>
								<li>
									Настоящие Правила являются официальным документом ООО
									"ТЕРЕМОК", юридического лица, созданного по законодательству
									Российской Федерации и зарегистрированное по адресу: 143085,
									Московская область, г.о. Одинцовский, рп. Заречье, ул.
									Весенняя, д. 1, к. 5, кв. 5 (далее – Администрация
									приложения), и определяют порядок обработки и защиты
									информации о физических лицах, пользующихся услугами
									интернет-сайта (приложения)
									<a href="https://teremok.io/">https://teremok.io/</a> (далее –
									Сайт, приложение) и его сервисов (далее – Пользователи).
								</li>
								<li>
									Целью настоящих Правил является обеспечение надлежащей защиты
									информации о пользователях, в том числе их персональных
									данных, от несанкционированного доступа и разглашения.
								</li>
								<li>
									Отношения, связанные со сбором, хранением, распространением и
									защитой информации о пользователях приложения, регулируются
									настоящими Правилами, иными официальными документами
									Администрации приложения и действующим законодательством
									Российской Федерации.
								</li>
								<li>
									Действующая редакция Правил, являющихся публичным документом,
									доступна любому пользователю сети Интернет при переходе по
									ссылке
									<a href="https://teremok.io/privacy">
										https://teremok.io/privacy </a
									>. Администрация приложения вправе вносить изменения в
									настоящие Правила. При внесении изменений в Правила
									Администрация приложения уведомляет об этом пользователей
									путем размещения новой редакции Правил в приложении по
									постоянному адресу
									<a href="https://teremok.io/privacy">
										https://teremok.io/privacy
									</a>
									не позднее, чем за 10 (десять) дней до вступления в силу
									соответствующих изменений. Предыдущие редакции Правил хранятся
									в архиве документации Администрации приложения.
								</li>
								<li>
									Настоящие Правила разработаны и используются в соответствии с
									Правилами пользования приложением
									<a href="https://teremok.io/">https://teremok.io/</a>,
									размещенными в приложении по адресу
									<a href="https://teremok.io/">https://teremok.io/</a>. В
									случае наличия противоречий между настоящими Правилами и иными
									официальными документами Администрации приложения применению
									подлежат настоящие Правила.
								</li>
								<li>
									Регистрируясь и используя Сайт, Пользователь выражает свое
									согласие с условиями настоящих Правил.
								</li>
								<li>
									В случае несогласия Пользователя с условиями настоящих Правил
									использование приложения и его сервисов должно быть немедленно
									прекращено.
								</li>
							</ol>
						</li>
						<li>
							<p><strong>Условия пользования приложением</strong></p>
							<ol>
								<li>
									Оказывая услуги по использованию приложения и его сервисов
									(далее – Услуги приложения), Администрация приложения,
									действуя разумно и добросовестно, считает, что Пользователь:
									<ol>
										<li>
											обладает всеми необходимыми правами, позволяющими ему
											осуществлять регистрацию и использовать настоящий Сайт;
										</li>
										<li>
											указывает достоверную информацию о себе в объемах,
											необходимых для пользования Услугами приложения;
										</li>
										<li>
											осознает, что информация в приложении, размещаемая
											Пользователем о себе, может становиться доступной для
											других Пользователей приложения и пользователей Интернета,
											может быть скопирована и распространена такими
											пользователями;
										</li>
										<li>
											осознает, что некоторые виды информации, переданные им
											другим Пользователям, не могут быть удалены самим
											Пользователем;
										</li>
										<li>
											ознакомлен с настоящими Правилами, выражает свое согласие
											с ними и принимает на себя указанные в них права и
											обязанности.
										</li>
									</ol>
								</li>
								<li>
									<p>
										Администрация приложения не проверяет достоверность
										получаемой (собираемой) информации о пользователях, за
										исключением случаев, когда такая проверка необходима в целях
										исполнения Администрацией приложения обязательств перед
										пользователем.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<p><strong>Цели обработки информации</strong></p>
							<ol>
								<li>
									Администрация приложения осуществляет обработку информации о
									Пользователях, в том числе их персональных данных, в целях
									выполнения обязательств Администрации приложения перед
									Пользователями в отношении использования приложения и его
									сервисов.
								</li>
							</ol>
						</li>
						<li>
							<p><strong>Состав информации о пользователях</strong></p>
							<ol>
								<li>
									<p>Персональные данные Пользователей</p>
									<br />
									<br />
									<p>Персональные данные Пользователей включают в себя:</p>
									<ol>
										<li>
											предоставляемые Пользователями и минимально необходимые
											для регистрации в приложении: фамилия, имя, отчество;
										</li>
										<li>
											предоставляемые Пользователями с использованием раздела
											редактирования своих страниц в приложении
											<a href="https://teremok.io/profile/[uuid]/edit">
												https://teremok.io/profile/[uuid]/edit
											</a>
											;
										</li>
										<li>
											дополнительно предоставляемые Пользователями по запросу
											Администрации приложения в целях исполнения Администрацией
											приложения обязательств перед Пользователями, вытекающих
											из договора на оказание Услуг приложения. Администрация
											приложения вправе, в частности, запросить у Пользователя
											копию документа, удостоверяющего личность, либо иного
											документа, содержащего имя, фамилию, фотографию
											Пользователя, а также иную дополнительную информацию,
											которая, по усмотрению Администрации приложения, будет
											являться необходимой и достаточной для идентификации
											такого Пользователя и позволит исключить злоупотребления и
											нарушения прав третьих лиц.
										</li>
									</ol>
								</li>
								<li>
									<p>
										Иная информация о Пользователях, обрабатываемая
										Администрацией приложения
									</p>
									<br />
									<br />
									<p>
										Администрация приложения может также обрабатывать иную
										информацию о Пользователях, которая включает в себя:
									</p>
									<ol>
										<li>
											дополнительные данные, получаемые при доступе к Сайту,
											включающие в себя данные о технических средствах
											(устройствах), технологическом взаимодействии с
											приложением (в т.ч. IP-адрес хоста, вид операционной
											системы пользователя, тип браузера, географическое
											положение, поставщик услуг Интернета, данные из адресной
											книги, данные, полученные в результате доступа к камере,
											микрофону и т.п. устройств), и последующих действиях
											Пользователя в приложении;
										</li>
										<li>
											информация, автоматически получаемая при доступе к Сайту с
											использованием закладок (cookies);
										</li>
										<li>
											информация, создаваемая пользователями в приложении вне
											раздела редактирования страниц
											<a href="https://teremok.io/profile/[uuid]/edit">
												https://teremok.io/profile/[uuid]/edit
											</a>
											(фотографии, аудиозаписи, видеозаписи, комментарии,
											отзывы);
										</li>
										<li>
											информация, полученная в результате действий Пользователя
											в приложении (размещении фотографий);
										</li>
										<li>
											информация, полученная в результате действий других
											пользователей в приложении.
										</li>
									</ol>
								</li>
							</ol>
						</li>
						<li>
							<p><strong>Обработка информации о пользователях</strong></p>
							<ol>
								<li>
									<p>
										Обработка персональных данных осуществляется на основе
										принципов:
									</p>
									<ul class="non-marker non-letters">
										<li>
											a) законности целей и способов обработки персональных
											данных;
										</li>
										<li>б) добросовестности;</li>
										<li>
											в) соответствия целей обработки персональных данных целям,
											заранее определенным и заявленным при сборе персональных
											данных, а также полномочиям Администрации приложения;
										</li>
										<li>
											г) соответствия объема и характера обрабатываемых
											персональных данных, способов обработки персональных
											данных целям обработки персональных данных;
										</li>
										<li>
											д) недопустимости объединения созданных для несовместимых
											между собой целей баз данных, содержащих персональные
											данные.
										</li>
									</ul>
									<ol>
										<li>
											<p>Условия и цели обработки персональных данных</p>
											<br />
											<br />
											<p>
												Администрация приложения осуществляет обработку
												персональных данных пользователя в целях исполнения
												договора между Администрацией приложения и Пользователем
												на оказание Услуг приложения (п. 2.2 Правил пользования
												приложением
												<a href="https://teremok.io/">https://teremok.io/</a> по
												адресу
												<a href="https://teremok.io/terms">
													https://teremok.io/terms
												</a>
												). В силу статьи 6 Федерального закона от 27.07.2006 №
												152-ФЗ «О персональных данных» отдельное согласие
												пользователя на обработку его персональных данных не
												требуется. В силу п.п. 2 п. 2 статьи 22 указанного
												закона Администрация приложения вправе осуществлять
												обработку персональных данных без уведомления
												уполномоченного органа по защите прав субъектов
												персональных данных.
											</p>
										</li>
										<li>
											<p>Сбор персональных данных</p>
											<br />
											<br />
											<p>
												Сбор персональных данных Пользователя осуществляется в
												приложении при регистрации, а также в дальнейшем при
												внесении пользователем по своей инициативе
												дополнительных сведений о себе с помощью инструментария
												приложения.
											</p>
										</li>
										<li>
											<p>
												Персональные данные, предусмотренные п. 4.1.1. настоящих
												Правил, предоставляются Пользователем и являются
												минимально необходимыми при регистрации.
											</p>
											<br />
											<br />
											<p>
												Персональные данные, предусмотренные п. 4.1.2. настоящих
												Правил, дополнительно предоставляются Пользователем по
												собственной инициативе с использованием вкладки кнопки
												"анкета" в разделе "профиль".
											</p>
										</li>
										<li>
											<p>Передача персональных данных</p>
											<br />
											<br />
											<p>
												Персональные данные Пользователей не передаются никаким
												другим третьим лицам, за исключением случаев, прямо
												предусмотренных настоящими Правилами.
											</p>
											<br />
											<br />
											<p>
												При указании Пользователя или при наличии согласия
												Пользователя возможна передача персональных данных
												Пользователя третьим лицам — контрагентам Администрации
												приложения с условием принятия такими контрагентами
												обязательств по обеспечению конфиденциальности
												полученной информации, в частности при использовании
												приложения.
											</p>
											<br />
											<br />
											<p>
												Предоставление персональных данных Пользователей по
												запросу государственных органов (органов местного
												самоуправления) осуществляется в порядке,
												предусмотренном законодательством. В целях исполнения
												соглашения между Пользователем и Администрацией
												приложения и предоставления Пользователю доступа к
												использованию функциональностей приложения,
												Администрация приложения развивает предоставляемые
												сервисы и продукты, разрабатывает и внедряет новые
												сервисы и продукты, оптимизирует качество сервисов и
												продуктов, совершенствует доступную функциональность
												приложения и сервисов. Для обеспечения реализации
												указанных целей Пользователь соглашается на
												осуществление Администрацией приложения с соблюдением
												применимого законодательства сервисных рассылок в его
												адрес (в том числе опросов) с целью получения обратной
												связи посредством сервисов Администрации приложения
												и/или сервисов третьих лиц: электронных сообщений, SMS и
												иных видов рассылок, а также сбора, хранения,
												накопления, систематизации, извлечения, сопоставления,
												использования, наполнения (уточнения) их данных, а также
												на получение и передачу аффилированным лицам и партнёрам
												результатов автоматизированной обработки таких данных с
												применением различных моделей оценки информации в виде
												целочисленных и/или текстовых значений и
												идентификаторов, соответствующих заданным в запросах
												оценочным критериям, для обработки данных Администрацией
												приложения и/или лицами, указанными в настоящем пункте.
											</p>
											<br />
											<br />
											<p>
												Регистрация и/или авторизация в приложении означает
												согласие с Пользовательским соглашением, которое
												размещено в открытом доступе в сети Интернет по адресу:
												<a href="https://teremok.io/terms">
													https://teremok.io/terms
												</a>
												и Правилами защиты информации о пользователях, которые
												размещены в открытом доступе в сети Интернет по адресу:
												<a href="https://teremok.io/privacy">
													https://teremok.io/privacy
												</a>
												.
											</p>
										</li>
										<li>
											<p>Уничтожение персональных данных</p>
											<br />
											<br />
											<p>Персональные данные пользователя уничтожаются при:</p>
											<ul class="marker non-letters">
												<li>
													самостоятельном удалении Пользователем данных со своей
													персональной страницы (аккаунта);
												</li>
												<li>
													обращении Пользователя в техническую поддержку через
													раздел "обратная связь" по соответствующей теме
													"удаление аккаунта";
												</li>
												<li>
													удалении Администрацией приложения информации,
													размещаемой Пользователем, а также персональной
													страницы (аккаунта) Пользователя в случаях,
													установленных Правилами пользования приложением
													Teremok
													<a href="https://teremok.io/terms">
														https://teremok.io/terms
													</a>
													(п.п. 7.2.2. и 8.6.).
												</li>
											</ul>
											<p>
												В случае удаления персональной страницы Администрация
												приложения хранит на своих электронных носителях
												персональные и иные необходимые данные Пользователя в
												течение необходимого и установленного действующим
												законодательством Российской Федерации срока.
											</p>
										</li>
									</ol>
								</li>
								<li>
									Размещая информацию на персональной странице (аккаунте), в том
									числе свои персональные данные, Пользователь осознает и
									соглашается с тем, что указанная информация может быть
									доступна другим пользователям сети Интернет с учетом
									особенностей архитектуры и функционала приложения.
								</li>
							</ol>
						</li>
						<li>
							<p><strong>Права и обязанности пользователей</strong></p>
							<ol>
								<li>
									Пользователи вправе:
									<ol>
										<li>
											осуществлять свободный бесплатный доступ к информации о
											себе посредством загрузки своих персональных страниц
											(аккаунтов) в приложении с использованием логина и пароля;
										</li>
										<li>
											самостоятельно вносить изменения и исправления в
											информацию о себе на персональной странице (в аккаунте)
											Пользователя в приложении, при условии, что такие
											изменения и исправления содержат актуальную и достоверную
											информацию;
										</li>
										<li>
											удалять информацию о себе со своей персональной страницы
											(аккаунта) в приложении;
										</li>
										<li>
											требовать от Администрации приложения уточнения своих
											персональных данных, их блокирования или уничтожения в
											случае, если такие данные являются неполными, устаревшими,
											недостоверными, незаконно полученными или не являются
											необходимыми для заявленной цели обработки и если
											невозможно самостоятельно выполнить действия,
											предусмотренные п.п. 6.1.2. и 6.1.3. настоящих Правил;
										</li>
										<li>
											на основании запроса получать от Администрации приложения
											информацию, касающуюся обработки его личных персональных
											данных.
										</li>
									</ol>
								</li>
								<li>
									Поскольку приложение является универсальным средством
									коммуникации и поиска жилья и главной функцией приложения
									является поиск жилья и бронирование этого жилья, то есть -
									создание заявок на бронирование, следующая информация о
									зарегистрированном Пользователе всегда доступна любому
									зарегистрированному Пользователю приложения:
									<ol>
										<li>фамилия и имя Пользователя;</li>
										<li>информация о интересах Пользователя;</li>
									</ol>
								</li>
								<li>
									Администрация приложения не несет ответственности за
									разглашение персональных данных Пользователя другими
									Пользователями приложения, получившими доступ к таким данным.
								</li>
								<li>
									При удалении персональных данных (иной пользовательской
									информации) с персональной страницы (аккаунта) Пользователя
									или удалении персональной страницы Пользователя с приложения,
									информация о Пользователе, скопированная другими
									Пользователями или хранящаяся на страницах других
									Пользователей, сохраняется.
								</li>
								<li>
									Обработка персональных данных, сделанных Пользователем
									доступными неограниченному кругу лиц, осуществляется
									Администрацией приложения на основании и в соответствии с
									условиями Правил пользования приложением Teremok и настоящих
									Правил.
								</li>
							</ol>
						</li>
						<li>
							<p><strong>Меры по защите информации о Пользователях</strong></p>
							<ol>
								<li>
									Администрация приложения принимает технические и
									организационно-правовые меры в целях обеспечения защиты
									персональных данных Пользователя от неправомерного или
									случайного доступа к ним, уничтожения, изменения,
									блокирования, копирования, распространения, а также от иных
									неправомерных действий.
								</li>
								<li>
									Для авторизации и доступа к приложению используется логин
									(номер мобильного телефона) и пароль Пользователя.
									Ответственность за сохранность данной информации несет
									Пользователь. Пользователь не вправе передавать собственный
									логин и пароль третьим лицам, а также обязан предпринимать
									меры по обеспечению их конфиденциальности.
								</li>
								<li>
									<p>
										В целях обеспечения более надежной защиты информации о
										Пользователях Администрация приложения использует систему
										привязки страницы (аккаунта) к мобильному телефону. Для
										осуществления данной системы Пользователь должен
										предоставить Администрации приложения номер своего
										мобильного телефона.
									</p>
									<br />
									<br />
									<p>
										В рамках системы привязки страницы (аккаунта) к мобильному
										телефону Пользователь в случае утраты логина или пароля
										может восстановить доступ к странице с помощью кода
										восстановления, содержащегося в SMS-сообщении, которое
										Пользователь получает на свой мобильный телефон.
									</p>
								</li>
								<li>
									Пользователь вправе получать информацию о том, в какое время и
									с каких устройств производилась авторизация на его
									персональную страницу (аккаунт), только после обращения в
									техническую поддержку через раздел обратной связи с
									соответствующей темой обращения "взлом аккаунта".
								</li>
							</ol>
						</li>
						<li>
							<p><strong>Ограничение действия Правил</strong></p>
							<br />
							<br />
							<p>
								Действие настоящих Правил не распространяется на действия и
								интернет-ресурсы третьих лиц.
							</p>
							<br />
							<br />
							<p>
								Администрация приложения не несет ответственности за действия
								третьих лиц, получивших в результате использования Интернета или
								Услуг приложения доступ к информации о Пользователе.
								Администрация приложения рекомендует Пользователям ответственно
								подходить к решению вопроса об объеме информации о себе,
								размещаемой в приложении.
							</p>
						</li>
						<li>
							<p><strong>Обращения пользователей</strong></p>
							<ol>
								<li>
									Пользователи вправе направлять Администрации приложения свои
									запросы, в том числе запросы относительно использования их
									персональных данных, предусмотренные п. 6.1.5 настоящих
									Правил, в письменной форме по адресу: 143085, Московская
									область, г.о. Одинцовский, рп. Заречье, ул. Весенняя, д. 1, к.
									5, кв. 5 или в форме электронного документа, подписанного
									квалифицированной электронной подписью в соответствии с
									законодательством Российской Федерации, по адресу электронной
									почты:
									<a href="mailto:teremokbusiness@gmail.com">
										teremokbusiness@gmail.com </a
									>.
								</li>
								<li>
									Запрос, направляемый пользователем, должен содержать следующую
									информацию:
									<ul class="marker non-letters">
										<li>
											сведения, подтверждающие участие пользователя в отношениях
											с оператором (в частности, порядковый номер аккаунта
											uuid);
										</li>
										<li>подпись пользователя или его представителя.</li>
									</ul>
								</li>
								<li>
									Администрация приложения обязуется рассмотреть и направить
									ответ на поступивший запрос пользователя в течение 30
									(тридцати) календарных дней с момента поступления обращения.
								</li>
								<li>
									Вся корреспонденция, полученная Администрацией приложения от
									пользователей (обращения в письменной или электронной форме),
									относится к информации ограниченного доступа и не разглашается
									без письменного согласия Пользователя. Персональные данные и
									иная информация о Пользователе, направившем запрос, не могут
									быть без специального согласия Пользователя использованы
									иначе, как для ответа по теме полученного запроса или в
									случаях, прямо предусмотренных законодательством.
								</li>
							</ol>
						</li>
					</ol>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: 'Privacy',
	methods: {
		goBack() {
			if (window.history.length > 2) {
				this.$router.go(-1)
			} else {
				window.close()
			}
		}
	}
}
</script>
